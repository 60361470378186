@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap");
@font-face {
  font-family: "ARCO";
  src: url("assets/fonts/ARCO.ttf");
}
@font-face {
  font-family: "Kicker Trial";
  src: url("assets/fonts/kickertrial.ttf");
}
// Rest css
* {
  padding: 0;
  margin: 0;
}
html,
body {
  font-family: "Inter", sans-serif;
  font-size: 16px;
}
ol,
ul {
  list-style: none;
}
img {
  vertical-align: top;
  width: 100%;
  border: none;
}
a {
  color: inherit;
  text-decoration: none;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
input {
  border: none;
}
a {
  cursor: pointer;
}
// Style
.App {
  .container {
    margin: 0 auto;
    padding: 0 336px;
    @media only screen and (max-width: 1550px) {
      padding: 0 236px;
    }
    @media only screen and (max-width: 1348px) {
      padding: 0 136px;
    }
    @media only screen and (max-width: 1024px) {
      padding: 0 80px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 40px;
    }
    @media only screen and (max-width: 568px) {
      padding: 0 24px;
    }
  }
  .page-header {
    .container {
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 90px;
      @media only screen and (max-width: 1348px) {
        gap: 40px;
      }
      @media only screen and (max-width: 1180px) {
        flex-direction: column;
        gap: 16px;
      }
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.05) 0%,
          rgba(255, 255, 255, 0.05) 100%
        ),
        #0c0319;
      .logo-app {
        width: 88px;
      }
      .nav-menu {
        display: flex;
        align-items: center;
        gap: 42px;
        @media only screen and (max-width: 968px) {
          flex-direction: column;
          gap: 12px;
        }
        li {
          color: var(--colors-nero, #fff);
          text-align: center;
          font-family: ARCO;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 48.6px; /* 194.4% */
          @media only screen and (max-width: 968px) {
            line-height: normal;
            font-size: 20px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
      .socials {
        display: flex;
        align-items: center;
        gap: 18px;
        img {
          width: 32px;
        }
      }
    }
  }
  .page-main {
    .section-intro {
      position: relative;
      background: url("assets/img/img-bg-1.png") no-repeat center 0;
      background-size: cover;
      padding: 86px 0 1019px;
      @media only screen and (max-width: 1024px) {
        padding: 86px 0 619px;
      }
      @media only screen and (max-width: 480px) {
        padding: 66px 0 419px;
      }
      .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .img-intro-1 {
          width: 558px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
        }
        .img-intro-2 {
          width: 906px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
        }
      }
      .img-present {
        position: absolute;
        width: 180px;
        bottom: 140px;
        left: 50%;
        transform: translateX(-50%);
        animation: slideFromRight 1s ease-out;
        @media only screen and (max-width: 1024px) {
          bottom: 100px;
        }
        @media only screen and (max-width: 768px) {
          bottom: 50px;
        }
        @media only screen and (max-width: 480px) {
          width: 100px;
        }
      }
    }
    .section-content {
      background: #02021e url("assets/img/img-bg-2.png") repeat center 0;
      background-size: cover;
      padding: 132px 0 110px;
      @media only screen and (max-width: 1024px) {
        padding: 80px 0 70px;
      }
      @media only screen and (max-width: 768px) {
        padding: 40px 0 30px;
      }
      @media only screen and (max-width: 480px) {
        padding: 30px 0 20px;
      }
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          color: #fff;
          font-family: Kicker Trial;
          font-size: 136px;
          font-style: normal;
          font-weight: 900;
          text-transform: uppercase;
          text-align: center;
          @media only screen and (max-width: 768px) {
            font-size: 76px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 46px;
          }
        }
        .subtitle {
          text-align: center;
          color: #fff;
          font-family: Kicker Trial;
          font-size: 95px;
          font-style: normal;
          font-weight: 900;
          text-transform: uppercase;
          padding-top: 32px;
          @media only screen and (max-width: 968px) {
            font-size: 65px;
          }
          @media only screen and (max-width: 768px) {
            font-size: 45px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 25px;
          }
        }
        .img-santa {
          width: 824px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
        }
        .content-wrapper {
          position: relative;
          .img-board {
            position: relative;
          }
          .content {
            position: absolute;
            top: 50%;
            left: 20%;
            transform: translate(-12%, -50%);
            .title {
              color: #fff;
              text-align: center;
              font-family: Irish Grover;
              font-size: 48px;
              font-style: normal;
              font-weight: 400;
              @media only screen and (max-width: 1660px) {
                font-size: 32px;
              }
              @media only screen and (max-width: 1180px) {
                font-size: 28px;
              }
              @media only screen and (max-width: 968px) {
                font-size: 20px;
              }
              @media only screen and (max-width: 620px) {
                font-size: 16px;
              }
              @media only screen and (max-width: 480px) {
                font-size: 14px;
              }
            }
            .des {
              padding: 15px 0;
              color: #fff;
              text-align: center;
              font-family: Inter;
              font-size: 25px;
              font-style: normal;
              font-weight: 400;
              @media only screen and (max-width: 1180px) {
                font-size: 20px;
              }
              @media only screen and (max-width: 968px) {
                font-size: 16px;
                padding: 10px 0;
              }
              @media only screen and (max-width: 768px) {
                font-size: 14px;
              }
              @media only screen and (max-width: 620px) {
                font-size: 12px;
                padding: 8px 0px;
              }
              @media only screen and (max-width: 480px) {
                font-size: 10px;
                padding: 8px 0px;
              }
              @media only screen and (max-width: 420px) {
                font-size: 8.5px;
                padding: 4px 0;
              }
            }
            .socials {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 20px;
              margin-top: 20px;
              @media only screen and (max-width: 480px) {
                gap: 12px;
              }
              .img-btn-link {
                position: relative;
                width: 200px;
                cursor: pointer;
                transition: all 0.3s linear;
                &:hover {
                  opacity: 0.75;
                }
                @media only screen and (max-width: 968px) {
                  width: 180px;
                }
                @media only screen and (max-width: 480px) {
                  width: 68px;
                }
              }
              .btn-telegram,
              .btn-twitter {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                text-align: center;
                font-family: ARCO;
                font-size: 19px;
                font-style: normal;
                font-weight: 700;
                @media only screen and (max-width: 768px) {
                  font-size: 14px;
                }
                @media only screen and (max-width: 480px) {
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
    }
    .section-utility {
      padding: 81px 0;
      background: #02021e url("assets/img/img-bg-3.png") no-repeat center 0;
      background-size: cover;
      @media only screen and (max-width: 1024px) {
        padding: 60px 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 40px 0;
      }
      @media only screen and (max-width: 480px) {
        padding: 30px 0;
      }
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .img-utility {
          width: 841px;
          @media only screen and (max-width: 1024px) {
            width: 100%;
          }
        }
        .box-wrapper {
          border-radius: 30px;
          border: 2px solid #2e469c;
          background: #fff;
          margin: 50px 0;
          padding: 16px 240px;
          @media only screen and (max-width: 1810px) {
            padding: 16px 120px;
          }
          @media only screen and (max-width: 1024px) {
            padding: 16px 60px;
          }
          @media only screen and (max-width: 480px) {
            padding: 16px 24px;
            margin: 24px 0;
          }
          .des {
            color: #2e469c;
            text-align: center;
            font-family: Inter;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: 48.6px; /* 194.4% */
            @media only screen and (max-width: 968px) {
              line-height: 32px;
              font-size: 20px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
        .img-santa-total {
          position: relative;
          width: 584px;
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
          .content {
            position: absolute;
            top: 70%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            h3 {
              color: #de0304;
              text-align: center;
              font-family: Inter;
              font-size: 25px;
              font-style: normal;
              font-weight: 500;
              line-height: 48.6px; /* 194.4% */
              text-transform: uppercase;
              @media only screen and (max-width: 768px) {
                font-size: 16px;
                line-height: normal;
              }
            }
            p {
              padding-top: 7px;
              color: #de0304;
              text-align: center;
              font-family: Inter;
              text-transform: uppercase;
              font-size: 47px;
              font-style: normal;
              font-weight: 900;
              line-height: 48.6px; /* 103.404% */
              @media only screen and (max-width: 768px) {
                font-size: 16px;
                line-height: normal;
              }
            }
          }
        }
      }
    }
    .section-tokenomics {
      padding: 82px 0;
      background: url("assets/img/img-bg-4.png") no-repeat center 0;
      background-size: cover;
      @media only screen and (max-width: 1024px) {
        padding: 60px 0;
      }
      @media only screen and (max-width: 768px) {
        padding: 40px 0;
      }
      @media only screen and (max-width: 480px) {
        padding: 30px 0;
      }
      .container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .img-token {
          width: 800px;
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
        }
        .img-banner-token {
          padding: 46px 0;
          position: relative;
          p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;
            font-family: Inter;
            font-size: 25px;
            font-style: normal;
            font-weight: 700;
            line-height: 48.6px; /* 194.4% */
            @media only screen and (max-width: 1280px) {
              font-size: 20px;
            }
            @media only screen and (max-width: 968px) {
              font-size: 18px;
              line-height: normal;
            }
            @media only screen and (max-width: 834px) {
              font-size: 14px;
            }
            @media only screen and (max-width: 645px) {
              font-size: 12px;
            }
            @media only screen and (max-width: 480px) {
              font-size: 10px;
            }
            @media only screen and (max-width: 420px) {
              font-size: 8.5px;
            }
          }
        }
        .list-present {
          display: flex;
          flex-wrap: wrap;
          .item-box {
            position: relative;
            flex-shrink: 0;
            width: 50%;
            p {
              color: #de0304;
              text-align: center;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              font-family: Inter;
              font-size: 47px;
              font-style: normal;
              font-weight: 900;
              line-height: 48.6px; /* 103.404% */
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;
              @media only screen and (max-width: 868px) {
                font-size: 32px;
                line-height: normal;
              }
              @media only screen and (max-width: 672px) {
                font-size: 24px;
              }
              @media only screen and (max-width: 480px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .section-slider {
      .item-slide {
        width: 628px;
        @media only screen and (max-width: 1024px) {
          width: 460px;
        }
        @media only screen and (max-width: 986px) {
          width: 320px;
        }
        @media only screen and (max-width: 480px) {
          width: 160px;
        }
      }
    }
  }
  .page-footer {
    background: #de0304;
    padding: 8px 0;
    .container {
      p {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 200% */
      }
    }
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
